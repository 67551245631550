import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, graphql } from "gatsby"

const PrivacyPolicyPage = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <div className="page-headline mt-5 about-bg">
        <div className="container">
          <div className="section-heading text-center ">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <h1 className="font-weight-bold text-uppercase text-white mb-4">
                  <strong>PRIVACY POLICY</strong>
                </h1>
                <p className="text-white" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="about-section" id="">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="container">
                <div className="content text-center">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="col">
                        <div class="tabs text-left">
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd1"
                              name="rd"
                            />
                            <label class="tab-label" for="rd1">
                              Age of Consent:
                            </label>
                            <div class="tab-content">
                              By using this website or one if its affiliates
                              under the “H-C Group” or “Heavenly-Candy” Brand,
                              you confirm that you are at least the minimum
                              required age needed, in your Province of
                              Residence, State or that you have given us your
                              consent to allow any of your minor dependents to
                              use this site with your permission.
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd2"
                              name="rd"
                            />
                            <label class="tab-label" for="rd2">
                              Cookies:
                            </label>
                            <div class="tab-content">
                              Our site and that of its affiliates under the “H-C
                              Group” or “Heavenly-Candy” Brand use ‘cookies’ to
                              help provide a better experience for you the user,
                              allowing us to measure which website areas are of
                              greatest interest. ‘Cookies’ may store information
                              useful to use that enables our website, or that of
                              its affiliates to remember you when you return. At
                              any time, you may choose to disable cookies using
                              the tools in your browser, however, this may limit
                              the advantages and functions provided on this
                              website and that of its affiliates.
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd3"
                              name="rd"
                            />
                            <label class="tab-label" for="rd3">
                              Security:
                            </label>
                            <div class="tab-content">
                              Protecting your personal information is very
                              important to us. We take reasonable precautious
                              and follow industry best practices, in order to
                              ensure that any information given to us is not
                              lost, misused, accessed, disclosed, altered, or
                              destroyed.
                            </div>
                            <div class="tab-content">
                              Any information with which you provide us with,
                              including, but not limited to credit card
                              information and other personal information, is
                              encrypted using what is known as Secure Socket
                              Layer Technology (SSL) and stored with a 256-Bit
                              encryption. While we can never guarantee 100% that
                              no method of transmission over the internet or
                              electronic platform is secure, we follow standards
                              to ensure that reasonable measures are taken and
                              maintained.
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd4"
                              name="rd"
                            />
                            <label class="tab-label" for="rd4">
                              Disclosures:
                            </label>
                            <div class="tab-content">
                              We may disclose your personal information when
                              required to do so by law enforcement or if our
                              Terms and Conditions are violated. We are not
                              required to inform you if this happens but will
                              not disclose your information under any other
                              circumstances, unless requested to do by a law
                              enforcement or other verified governing agency.
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd5"
                              name="rd"
                            />
                            <label class="tab-label" for="rd5">
                              Third-Party Services:
                            </label>
                            <div class="tab-content">
                              We will never share or sell you information with
                              any third-party without your permission. Your
                              name, email, phone and address, along with other
                              personal information, will be used solely for
                              order-related communication and shipments. We do
                              not store credit card information, unless
                              instructed to do so otherwise, which is ran
                              securely to prevent interception by third-parties.
                              We use Google Site Stats for tracking sales made
                              through our Google and other advertisements.
                              Though none of your personal information is being
                              used or recorded in any way, you may disable this
                              cookie if you wish to do so.
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd6"
                              name="rd"
                            />
                            <label class="tab-label" for="rd6">
                              Where Your Information Goes and What is Done with
                              it?
                            </label>
                            <div class="tab-content">
                              When using our site and that of its affiliates
                              under the “H-C Group” or “Heavenly-Candy” Brand,
                              we collect the personal information you provide us
                              with, such as your name, email address, address,
                              phone number and other information used during the
                              buying and selling process.
                            </div>
                            <div class="tab-content">
                              If agreed to, you may receive email marketing
                              emails and text messages from us with offers. If
                              you opted to receive marketing communication, it
                              would come in one of these two forms.
                            </div>
                            <div class="tab-content">
                              When on our site or that of its affiliates, your
                              computer or devices Internet Protocol (IP) address
                              is automatically received by our system, in order
                              to give us information about your devices
                              operating system.
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd7"
                              name="rd"
                            />
                            <label class="tab-label" for="rd7">
                              Amending or Changing Privacy Policy:
                            </label>
                            <div class="tab-content">
                              At any time, we reserve the right to modify or
                              change this privacy policy, or that of any of its
                              affiliates. We ask you to review it frequently if
                              you need to do so. If a change an amendment is
                              made, they will take effect immediately, even if
                              not immediately posted to the website. If a change
                              is made, it will be posted here to notify you.
                            </div>
                          </div>

                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd99"
                              name="rd"
                            />
                            <label for="rd99" class="tab-close">
                              Close others &times;
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PrivacyPolicyPage
